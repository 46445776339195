<template>
  <div class="audience-age-and-gender d-lg-flex flex-column min-height-100">
    <div class="text-h6 font-weight-bold mb-4">
      Audience Type
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          Percentage of the types of the users the followers are.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft flex-grow-1">
      <apex-chart
        type="donut"
        height="380"
        :options="options"
        :series="series"
      />
    </v-card>
  </div>
</template>

<script>
// Import node packages
import ApexChart from 'vue-apexcharts'
import merge from 'lodash/merge'

// Import default configurations object
import apexChartBaseOptions from '@/json/apexChartBaseOptions.js'

// Export the SFC
export default {
  // Name of the component
  name: "AudienceType",

  // Register the component
  components: {
    ApexChart
  },

  // Accept incoming data form parent
  props: {
    allData: {
      type: Object,
      required: true
    },

    audienceData: {
      type: Object,
      required: true
    },

    totalValues: {
      type: Object,
      required: true
    },

    allTabs: {
      type: Array,
      required: true
    },

    selectedTab: {
      type: String,
      required: true
    }
  },

  // Define readonly computable properties
  computed: {
    /**
     * Get the series data for apex-chart
     *
     * @returns {Array}
     */
    series() {
      // reversing to make sure male is first and female later
      return this.audienceData?.audienceTypes.map((v) => v?.weight * 100)
    },

    /**
     * Get the configurations object for apex-chart
     *
     * @returns {Object}
     */
    options() {
      return merge(apexChartBaseOptions(), {
        labels: this.audienceData?.audienceTypes.map((v) => `${capitalizeString(v.code.replace('_', ' '))}: ${numeral.fPercent(v.weight * 100)}`),
        chart: {
          toolbar: {
            show: false
          }
        },
        colors: [
          "#cf6d2b",
          "#f05d56",
          "#067518",
          "#31e84e"
        ],
        stroke: { colors: ["#fff"] },
        legend: {
          show: this.showLegend,
          floating: false,
          horizontalAlign: "center",
          position: "top",
          labels: {
            // colors: ["#000000"],
            colors: [host.theme.color.text],
            useSeriesColors: false,
          },
          offsetY: 20
        },
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: true,
            opacity: 0.1
          },
          style: {
            fontSize: "13px",
            colors: ["#000", "#000"]
          }
        },
        tooltip: {
          fillSeriesColor: false,
          y: {
            formatter: (seriesName) => numeral.fPercent(seriesName),
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.8,
            startAngle: 180,
            endAngle: 180 + 360,
            donut: {
              labels: {
                show: false
              },
              value: {
                show: false
              }
            },
          },
        },
      })
    }
  }
}
</script>
